import React, { useContext } from 'react';
import SspProviderContext from '../../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import VideoPlayer from '../../VideoPlayer';
import SSPAssetDataSetAccessor from 'sspcontentext/dist/model/accessor/SSPAssetDataSetAccessor';
import Default from '../../../assets/images/Default.jpg';
import i18next from 'i18next';

export default function Overview(): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;

    function renderMediaAsset(mediaAsset?: SSPAssetDataSetAccessor) {
        if (mediaAsset?.fileFamily === 'image') {
            return <img src={mediaAsset.url} alt='your-car' />;
        } else if (mediaAsset?.fileFamily === 'video') {
            return (
                <VideoPlayer
                    link={mediaAsset.url}
                    filetype={mediaAsset.fileProperties.fileType}
                    loopVideo={true}
                    hideControl={true}
                />
            );
        }
        return <></>;
    }

    let overviewAsset;
    if (contentModel.has('driving_scene_asset')) {
        overviewAsset = contentModel.ref('driving_scene_asset');
    } else {
        overviewAsset = contentModel.reflist('header_assets').find(asset => {
            return asset.asModel.isinStrlist('tags', 'front:left:false:');
        });
    }

    return (
        <div id='overview'>
            <div className='overview-img'>
                {overviewAsset ? renderMediaAsset(overviewAsset?.asAsset) : <img src={Default} alt='Default Image' />}
            </div>
            <div className='overview-welcome'>
                <div className='overview-logo'>
                    <div className='logo-line' />
                    <svg>
                        <use xlinkHref='#vw-logo' />
                    </svg>
                    <div className='logo-line' />
                </div>
                <span>
                    <strong> {i18next.t('Your')}</strong>
                </span>
                {contentModel.str('title') !== undefined ? <span>{contentModel.str('title')}</span> : <span>--</span>}
                <div className='overview-welcome-text'>{i18next.t('OverviewWelcomeText')}</div>
            </div>
        </div>
    );
}

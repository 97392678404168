import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { PageProps } from './interfaces';
import './styles.scss';

import Menu from '../Menu';
import { useLocation } from 'react-router-dom';
import CustomLink from '../CustomLink';
import { OverlayEnum, PageChildrenEnum } from '../../enums';
import Overlay from '../Overlay';
import SspProviderContext from '../../utils/SspProvider/context';
import { SET_EXPOSE_ARROW_HIDDEN_ACTION } from '../../utils/SspProvider/store';
import { setOverlay } from '../../utils/SspProvider/dispatchFunctions';

export default function Page(props: PageProps): JSX.Element {
    const store = useContext(SspProviderContext);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [, setOverlayVisible] = useState<boolean>(false);
    const [yOffset, setYOffset] = useState<number>(0);
    const [pageStyle, setPageStyle] = useState<CSSProperties>({});

    const isExposeArrowHidden = store.state.exposeArrowHidden;
    const iFrameRef = useRef<HTMLIFrameElement>(null);

    const iFrameSrc = props.iframeSrc;

    /*** scrolls to the element with the id given by the #hash in the url ***/
    const location = useLocation();
    const pageHeaderRef = useRef<HTMLDivElement>(null);

    const overlayWithTransition =
        store.state.overlay && store.state.overlay.enum && !(store.state.overlay.enum === OverlayEnum.Loading);

    const closeable = props.child === PageChildrenEnum.Closeable && iFrameSrc === undefined;

    useEffect(() => {
        // if not a hash link, scroll to top
        if (location.hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = location.hash.replace('#', '');
                const element = document.getElementById(id);
                if (element && pageHeaderRef.current) {
                    let position = element.offsetTop - pageHeaderRef.current.offsetHeight;
                    if (id === 'checkin') {
                        position = position + 50;
                    }
                    window.scrollTo({ top: position, behavior: 'smooth' });
                }
            }, 0);
        }
    }, [location, store.state.loadingProgress]);

    useEffect(() => {
        // remove exposé arrow from DOM after 5s
        if (!isExposeArrowHidden) {
            const timer = setTimeout(() => {
                store.dispatch({ type: SET_EXPOSE_ARROW_HIDDEN_ACTION, value: !isExposeArrowHidden });
            }, 5000);
            return () => clearTimeout(timer);
        }
    });

    useEffect(() => {
        setOverlayVisible((prevState: boolean) => {
            const opened = store.state.overlay?.enum === OverlayEnum.ExternalContent && !!store.state.overlay;
            if (opened) {
                setYOffset(window.pageYOffset);
                setPageStyle({
                    overflowY: 'hidden',
                    position: 'fixed',
                    top: -window.scrollY + 'px',
                });
            }
            if (prevState && !store.state.overlay) {
                setPageStyle({});
            }
            return opened;
        });
    }, [store.state.overlay]);

    useEffect(() => {
        if (!pageStyle.top) {
            window.scrollTo({ top: yOffset });
        }
    }, [pageStyle, yOffset]);

    return (
        <div className={`page${closeable ? ' closeable' : ''}`}>
            {store.state.loadingProgress === 1 && (
                <div
                    className='page-content'
                    style={props.child === PageChildrenEnum.Map ? { ...pageStyle, paddingBottom: 'unset' } : pageStyle}
                >
                    {props.children}
                </div>
            )}
            {iFrameSrc && iFrameSrc !== '' && (
                <>
                    <iframe
                        className='iframe'
                        ref={iFrameRef}
                        src={iFrameSrc}
                        title={'Hier wird die Webseite der Autostadt eingebunden.'}
                    />
                    {props.closeIframe && (
                        <button className='close-iframe-button' onClick={() => props.closeIframe()}>
                            <svg>
                                <use xlinkHref='#close' />
                            </svg>
                        </button>
                    )}
                </>
            )}
            {props.child !== PageChildrenEnum.CheckIn && !props.iframeSrc && (
                <div className={'page-header'} ref={pageHeaderRef}>
                    {!closeable ? (
                        <svg className='header-burger' onClick={() => setMenuOpen(!menuOpen)}>
                            <use xlinkHref='#menu-burger' />
                        </svg>
                    ) : (
                        <svg className='header-close' onClick={() => window.history.back()}>
                            <use xlinkHref='#close' />
                        </svg>
                    )}
                    <svg className='header-title'>
                        <use xlinkHref='#autostadt' />
                    </svg>
                </div>
            )}
            {!isExposeArrowHidden && props.child == PageChildrenEnum.Expose && (
                <svg className={'chevron bounce'}>
                    <use xlinkHref='#double-chevron-right' />
                </svg>
            )}
            {!closeable && (
                <div className={'page-footer'}>
                    {(props.child === PageChildrenEnum.Back || props.child === PageChildrenEnum.CheckIn) && (
                        <span className='back-container' onClick={() => window.history.back()}>
                            <svg className='back-arrow'>
                                <use xlinkHref='#back-arrow' />
                            </svg>
                            <span className='back-text'>Zurück</span>
                        </span>
                    )}
                    {props.child !== PageChildrenEnum.Back && props.child !== PageChildrenEnum.CheckIn && (
                        <>
                            <CustomLink to='/'>
                                <div
                                    className={`footer-item${
                                        props.child === PageChildrenEnum.Expose ? ' active-item' : ''
                                    }`}
                                >
                                    <svg className='icon' style={{ stroke: 'none' }}>
                                        <use xlinkHref='#car' />
                                    </svg>
                                </div>
                            </CustomLink>
                            {props.editorialContent && (
                                <CustomLink to='/story'>
                                    <div
                                        className={`footer-item${
                                            props.child === PageChildrenEnum.Story ? ' active-item' : ''
                                        }`}
                                    >
                                        <svg className='icon'>
                                            <use xlinkHref='#story' />
                                        </svg>
                                    </div>
                                </CustomLink>
                            )}

                            <CustomLink to='/pick-up'>
                                <div
                                    className={`footer-item${
                                        props.child === PageChildrenEnum.PickUp ? ' active-item' : ''
                                    }`}
                                >
                                    <svg className='icon'>
                                        <use xlinkHref='#home' />
                                    </svg>
                                </div>
                            </CustomLink>
                            <CustomLink to='/map'>
                                <div
                                    className={`footer-item${
                                        props.child === PageChildrenEnum.Map ? ' active-item' : ''
                                    }`}
                                >
                                    <svg className='icon'>
                                        <use xlinkHref='#map' />
                                    </svg>
                                </div>
                            </CustomLink>
                        </>
                    )}
                </div>
            )}
            {store.state.overlay && !overlayWithTransition && !iFrameSrc && (
                <div
                    className={`page-overlay-container overlay-open`}
                    onClick={() => {
                        setOverlay(store, null);
                    }}
                    id='without-transition'
                >
                    <meta name='theme-color' content='#333333' />
                    <Overlay />
                </div>
            )}
            {!iFrameSrc && (
                <div className={`page-overlay-container transition${overlayWithTransition ? ' overlay-open' : ''}`}>
                    <Overlay hideVideo={true} />
                </div>
            )}
            <div className={`page-menu-container${menuOpen ? ' menu-open' : ''}`}>
                <Menu setMenuOpen={setMenuOpen} editorialContent={props.editorialContent} />
            </div>
        </div>
    );
}

import React, { useContext, useState } from 'react';
import SspProviderContext from '../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import './cardetails.scss';

export default function CarDetails(props: { CarEnum: number }): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const engine = contentModel.ref('engine').asModel;
    const standardEquipList = contentModel.reflist('serie_equip');
    const optionalEquipList = contentModel.reflist('special_equip');
    const highlightEquipList = contentModel.reflist('highlight_equip');

    const standardCategories = [
        ...new Set(standardEquipList.map(standardEquipItem => standardEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const optionalCategories = [
        ...new Set(optionalEquipList.map(optionalEquipItem => optionalEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');
    const highlightCategories = [
        ...new Set(highlightEquipList.map(highlightEquipItem => highlightEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const [currentStandardCategory, setCurrentStandardCategory] = useState<number>(-1);
    const [currentOptionalCategory, setCurrentOptionalCategory] = useState<number>(-1);
    const [currentHighlightCategory, setCurrentHighlightCategory] = useState<number>(-1);

    const subsContainer = (filteredEquipItem: SSPContentVO): JSX.Element => (
        <div className='sub' key={filteredEquipItem.asModel.str('key')}>
            {filteredEquipItem.asModel.str('layertitle') && <p>{filteredEquipItem.asModel.str('layertitle')}</p>}
            {filteredEquipItem.asModel.str('description') && <p>{filteredEquipItem.asModel.str('description')}</p>}
            {filteredEquipItem.asModel.str('details') && <p>{filteredEquipItem.asModel.str('details')}</p>}
            {filteredEquipItem.asModel.reflist('assets') && filteredEquipItem.asModel.reflist('assets')[0] && (
                <img
                    alt={filteredEquipItem.asModel.str('layertitle')}
                    src={filteredEquipItem.asModel.reflist('assets')[0].asAsset.url}
                />
            )}
        </div>
    );

    return (
        <div className='detail-table-area'>
            {props.CarEnum === 1 && (
                <table className='detail-table-overview'>
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    <h5>
                                        <svg>
                                            <use xlinkHref='#power' />
                                        </svg>
                                        Leistung
                                    </h5>
                                    <p>
                                        {' '}
                                        {engine.has('fuel_power')
                                            ? `${engine.str('fuel_power')} KW`
                                            : engine.str('engine_type') === 'Electric'
                                              ? `${engine.str('fuel_power2')} KW`
                                              : '-'}
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <h5>
                                        <svg>
                                            <use xlinkHref='#gear' />
                                        </svg>
                                        Getriebe
                                    </h5>
                                    {engine !== undefined && engine.str('motor_gear') !== undefined ? (
                                        <p>{engine.str('motor_gear')}</p>
                                    ) : (
                                        <p>--</p>
                                    )}
                                </div>
                            </td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>*/}
                        {/*        <svg>*/}
                        {/*            <use xlinkHref='#emission'/>*/}
                        {/*        </svg>*/}
                        {/*        <div>*/}
                        {/*            <h5>Emission</h5>*/}
                        {/*            <div className='in-circle'*/}
                        {/*                 onClick={() => setOverlay(store, {disclaimer: 1})}>1*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <p>{vehicleData.str('nedc_co2_combined') + ' g/km'}</p>*/}
                        {/*    </td>*/}
                        {/*    <td>*/}
                        {/*        <svg>*/}
                        {/*            <use xlinkHref='#consumption'/>*/}
                        {/*        </svg>*/}
                        {/*        <div>*/}
                        {/*            <h5>Verbrauch</h5>*/}
                        {/*            <div className='in-circle'*/}
                        {/*                 onClick={() => setOverlay(store, {disclaimer: 1})}>1*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <p>{vehicleData.float('nedc_consumption_combined').toLocaleString('de-DE') +*/}
                        {/*            consumptionUnit}</p>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td>
                                <div>
                                    <h5>
                                        <svg>
                                            <use xlinkHref='#motor' />
                                        </svg>
                                        Antrieb
                                    </h5>
                                    <p>{engine.str('motor_drive')}</p>
                                </div>
                            </td>
                            {/*<td>*/}
                            {/*    <svg>*/}
                            {/*        <use xlinkHref='#efficiency'/>*/}
                            {/*    </svg>*/}
                            {/*    <div>*/}
                            {/*        <h5>Effizienzklasse</h5>*/}
                            {/*        <div className='in-circle'*/}
                            {/*             onClick={() => setOverlay(store, {disclaimer: 2})}>2*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className='efficiency-class'>*/}
                            {/*        <div*/}
                            {/*            className='efficiency-class-arrow'>{vehicleData.str('nedc_energy_efficiency')}</div>*/}
                            {/*    </div>*/}
                            {/*</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <h5>
                                        <svg>
                                            <use xlinkHref='#fuel' />
                                        </svg>
                                        Kraftstoffart
                                    </h5>
                                    <p>
                                        {engine.str('engine_type') === 'Electric'
                                            ? engine.str('fuel2')
                                            : engine.str('fuel_type')}
                                    </p>{' '}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
            {props.CarEnum === 2 && (
                <div className='detail-table-highlight'>
                    {highlightCategories.map((category, index) => (
                        <React.Fragment key={`highlight-categories-${index}`}>
                            <div
                                className={`headline ${currentHighlightCategory === index ? 'open' : 'closed'}`}
                                onClick={() => {
                                    setCurrentHighlightCategory(currentHighlightCategory === index ? -1 : index);
                                }}
                            >
                                <h5>{category}</h5>
                                {true && (
                                    <button className='td-button'>
                                        <svg>
                                            <use xlinkHref='#chevron-narrow-right' />
                                        </svg>
                                    </button>
                                )}
                            </div>

                            {currentHighlightCategory === index && (
                                <div className='subs-container'>
                                    {highlightEquipList
                                        .filter(equipItem => equipItem.asModel.str('equipcategory') === category)
                                        .filter(cat => cat)
                                        .map(filteredEquipItem => subsContainer(filteredEquipItem))}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
            {props.CarEnum === 3 && (
                <div className='detail-table-optional'>
                    {optionalCategories.map((category, index) => (
                        <React.Fragment key={`optional-categories-${index}`}>
                            <div
                                className={`headline ${currentOptionalCategory === index ? 'open' : 'closed'}`}
                                onClick={() => {
                                    setCurrentOptionalCategory(currentOptionalCategory === index ? -1 : index);
                                }}
                            >
                                <h5>{category}</h5>
                                {true && (
                                    <button className='td-button'>
                                        <svg>
                                            <use xlinkHref='#chevron-narrow-right' />
                                        </svg>
                                    </button>
                                )}
                            </div>

                            {currentOptionalCategory === index && (
                                <div className='subs-container'>
                                    {optionalEquipList
                                        .filter(equipItem => equipItem.asModel.str('equipcategory') === category)
                                        .filter(cat => cat)
                                        .map(filteredEquipItem => subsContainer(filteredEquipItem))}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
            {props.CarEnum === 4 && (
                <div className='detail-table-standard'>
                    {standardCategories.map((category, index) => (
                        <React.Fragment key={`standard-categories-${index}`}>
                            <div
                                className={`headline ${currentStandardCategory === index ? 'open' : 'closed'}`}
                                onClick={() => {
                                    setCurrentStandardCategory(currentStandardCategory === index ? -1 : index);
                                }}
                            >
                                <h5>{category}</h5>
                                {true && (
                                    <button className='td-button'>
                                        <svg>
                                            <use xlinkHref='#chevron-narrow-right' />
                                        </svg>
                                    </button>
                                )}
                            </div>

                            {currentStandardCategory === index && (
                                <div className='subs-container'>
                                    {standardEquipList
                                        .filter(equipItem => equipItem.asModel.str('equipcategory') === category)
                                        .filter(cat => cat)
                                        .map(filteredEquipItem => subsContainer(filteredEquipItem))}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
}

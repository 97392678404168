import React, { useContext } from 'react';
import PreviewTile from '../../PreviewTile';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import SspProviderContext from '../../../utils/SspProvider/context';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import { EditorialContentProps } from '../../Page/interfaces';
import { CarDetailEnum } from '../../../enums';
import i18next from 'i18next';
import sliderSettings from '../../../utils/SliderSettings';

export default function Details(props: EditorialContentProps): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const standardEquipList = contentModel.reflist('serie_equip');
    const optionalEquipList = contentModel.reflist('special_equip');
    const highlightEquipList = contentModel.reflist('highlight_equip');

    const standardCategories = [
        ...new Set(standardEquipList.map(standardEquipItem => standardEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const optionalCategories = [
        ...new Set(optionalEquipList.map(optionalEquipItem => optionalEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');
    const highlightCategories = [
        ...new Set(highlightEquipList.map(highlightEquipItem => highlightEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    function findFirstInstance(arr: SSPContentVO[]) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].asModel.has('assets')) {
                return arr[i].asModel.reflist('assets')[0].asAsset.url;
            }
        }
        return undefined;
    }

    const highlightImg = findFirstInstance(highlightEquipList);
    const standardImg = findFirstInstance(standardEquipList);
    const optionalImg = findFirstInstance(optionalEquipList);

    return (
        <div id='details'>
            <h2>{i18next.t('Details')}</h2>
            <Slider className='swipeable-view' {...sliderSettings}>
                <PreviewTile
                    tileType={'large'}
                    button={i18next.t('Overview')}
                    onClick={() => {
                        setOverlay(store, {
                            title: i18next.t('Overview'),
                            carDetails: CarDetailEnum.Overview,
                        });
                    }}
                />
                {!props.editorialContent && highlightCategories.length > 0 && (
                    <PreviewTile
                        tileType={'large'}
                        button={i18next.t('EquipmentHighlights')}
                        img={highlightImg}
                        onClick={() => {
                            setOverlay(store, {
                                title: i18next.t('EquipmentHighlights'),
                                carDetails: CarDetailEnum.Highlight,
                            });
                        }}
                    />
                )}
                {optionalCategories.length > 0 && (
                    <PreviewTile
                        tileType={'large'}
                        button={i18next.t('SpecialEquipment')}
                        img={optionalImg}
                        onClick={() => {
                            setOverlay(store, {
                                title: i18next.t('SpecialEquipment'),
                                carDetails: CarDetailEnum.Optional,
                            });
                        }}
                    />
                )}
                {standardCategories.length > 0 && (
                    <PreviewTile
                        tileType={'large'}
                        button={i18next.t('StandardEquipment')}
                        img={standardImg}
                        onClick={() => {
                            setOverlay(store, {
                                title: i18next.t('StandardEquipment'),
                                carDetails: CarDetailEnum.Standard,
                            });
                        }}
                    />
                )}
            </Slider>
        </div>
    );
}
